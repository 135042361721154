import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const InvitersDisplay = () => {
    const [inviters, setInviters] = useState([]);
    const { idToken } = useContext(AuthContext);

    useEffect(() => {
        axios.get('https://extreme-wind-446702-f9.et.r.appspot.com/inviters', {
            headers: { 'Authorization': `Bearer ${idToken}` }
        })
        .then(response => {
            // Assuming the response includes profile pictures
            setInviters(response.data);
        })
        .catch(error => console.error('Error fetching inviters:', error));
    }, [idToken]);

    return (
        <div className="inviters-display">
            <h2 className="section-title">Invited by</h2>
            {inviters.length === 0 ? (
                <p className="no-inviter-message">No inviters found</p>
            ) : (
                <div className="inviter-details-container">
                    {inviters.map(inviter => (
                        <div className="inviter" key={inviter.uid}>
                            <img src={inviter.profilePicture || 'default-profile-url'} alt={`${inviter.firstName}'s profile`} className="inviter-profile-picture"/>
                            <p className="inviter-detail">{inviter.firstName} {inviter.lastName}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default InvitersDisplay;
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const InvitationForm = ({ onCodeGenerated, onError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, idToken } = useContext(AuthContext); // Access the current user

    // Ensure you have a valid currentUser and idToken
    if (!currentUser || !idToken) {
        console.error('Missing currentUser or idToken');
        return <div>Authentication required.</div>;
    }

    const uid = currentUser.uid; // Get UID from the current user

    const generateCode = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`https://extreme-wind-446702-f9.et.r.appspot.com/generate_invitations_for_user/${uid}`, {}, {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success && response.data.codes) {
                onCodeGenerated(response.data.codes[0]); // Assuming the first code is what we need
            } else {
                onError(response.data.message);
            }
        } catch (err) {
            onError(err.response?.data?.message || err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <button onClick={generateCode} disabled={isLoading} className='btn generate-code-btn'><span className='plus-symbol'>+ </span> Generate Code</button>
        </div>
    );
};

export default InvitationForm;

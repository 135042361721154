import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import OnboardingSlideshowPartialUser from '../components/OnboardingSlideshowPartialUser';

const MiniDashboardPage = () => {
    const [secondCode, setSecondCode] = useState('');
    const [message, setMessage] = useState('');
    const { currentUser, idToken, userRole } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to login if not authenticated
        if (!currentUser || !idToken) {
            navigate('/login');
        }
    }, [currentUser, idToken, navigate]);

    if (!['admin', 'partialuser'].includes(userRole)) {
        // Redirecting for users who are authenticated but do not have the correct role
        return <Navigate to="/dashboard" />;;
    }

    const handleProfileClick = () => {
        navigate('/profile');
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    const handleSecondCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://extreme-wind-446702-f9.et.r.appspot.com/confirm_second_code',
                {
                    invitee: currentUser.uid,
                    code2: secondCode
                },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    }
                }
            );
            if (response.data.success) {
                setMessage('Second code confirmed successfully.');
                setTimeout(() => {
                    navigate('/welcome-new-full-users'); // Navigate to dashboard after confirmation
                }, 1000);
            } else {
                setMessage(response.data.message || 'Failed to confirm the second code.');
            }
        } catch (error) {
            setMessage(error.response?.data?.message || 'An error occurred.');
        }
    };

    return (
        <div className="mini-dashboard-container">
            <h1 className="mini-dashboard-header">Dashboard</h1>
            <button className="mini-dashboard-btn" onClick={handleProfileClick}>User Profile</button>
            <button className="mini-dashboard-btn" onClick={handleSettingsClick}>Settings</button>

            <h2 className="second-code-form-header">Enter Second Code</h2>
            <form className="mini-dashboard-form" onSubmit={handleSecondCodeSubmit}>
                <label htmlFor="secondCode" className="mini-dashboard-label"></label>
                <input 
                    type="text" 
                    id="secondCode"
                    className="mini-dashboard-input" 
                    value={secondCode} 
                    onChange={(e) => setSecondCode(e.target.value)}
                    placeholder="Enter second invitation code here" 
                />
                <button type="submit" className="mini-dashboard-submit">Validate</button>
            </form>

            <OnboardingSlideshowPartialUser />

            {message && <p className="mini-dashboard-message">{message}</p>}
        </div>
    );
};

export default MiniDashboardPage;

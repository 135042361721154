import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const InvitationDetails = ({ code }) => {
    const [invitation, setInvitation] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser, idToken } = useContext(AuthContext); // Access the current user and idToken

    useEffect(() => {
        // Define an async function inside useEffect
        const fetchInvitationDetails = async () => {
            if (!currentUser || !idToken) {
                console.error('Missing currentUser or idToken');
                return;
            }

            setLoading(true);
            try {
                const response = await axios.post(
                    'https://extreme-wind-446702-f9.et.r.appspot.com/invitation_details', 
                    { code }, 
                    {
                        headers: {
                            'Authorization': `Bearer ${idToken}`,
                        }
                    }
                );
                setInvitation(response.data);
            } catch (error) {
                console.error("Error fetching invitation details:", error);
            } finally {
                setLoading(false);
            }
        };

        if (code) {
            fetchInvitationDetails();  // Call the async function
        }
    }, [code, currentUser, idToken]); // Depend on code, currentUser, and idToken

    if (!code) return null;
    if (loading) return <p>Loading...</p>;

    return (
        <div className="invitation-details">
            {invitation && (
                <>
                    <p className='invitation-details-titles'>Code: {invitation.code}</p>
                    <p><span className='invitation-details-titles'>Created:</span> {new Date(invitation.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                    {console.log(invitation.created_at)}
                    {invitation.active && <p><span className='invitation-details-titles'>Expires On:</span> {new Date(new Date(invitation.created_at).setDate(new Date(invitation.created_at).getDate() + 31)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>}
                    {invitation.used && <p ><span className='invitation-details-titles'>Status:</span> Used</p>}
                    {invitation.usedOn && <p><span className='invitation-details-titles'>Used On:</span> {new Date(invitation.usedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>}
                    {invitation.used && <p><span className='invitation-details-titles'>Used By:</span> {invitation.usedBy}</p>}
                    {invitation.revoked && <p><span className='invitation-details-titles'>Status:</span> Revoked</p>}
                    {invitation.expired && <p><span className='invitation-details-titles'>Status:</span> Expired</p>}
                    {/* Add more details as needed */}
                </>
            )}
        </div>
    );
};

export default InvitationDetails;
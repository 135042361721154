import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const UserProfileDetails = () => {
    const { idToken, currentUser } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        job: '',
        company: '',
        profilePicture: 'default-profile-pic-url',
    });

    useEffect(() => {
        if (currentUser && currentUser.uid) {
            axios.get(`https://extreme-wind-446702-f9.et.r.appspot.com/profile`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            })
            .then(response => {
                setUserDetails({
                    ...userDetails,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    job: response.data.job,
                    company: response.data.company,
                    profilePicture: response.data.profilePicture || userDetails.profilePicture,
                });
            })
            .catch(error => {
                console.error('Error fetching user profile details:', error);
            });
        }
    }, [idToken, currentUser]);

    return (
        <div className="user-profile-details">
            <div className="profile-header">
                <img className="profile-picture" src={userDetails.profilePicture} alt="Profile" />
                <div className="user-container">
                    <h1 className="user-name">{userDetails.firstName} {userDetails.lastName}</h1>
                    <div className="profile-info">
                        <p className="user-position">{userDetails.job}</p>
                        <p className="user-company">{userDetails.company}</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default UserProfileDetails

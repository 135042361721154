import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const EnterInvitationPage = () => {
    const [invitationCode, setInvitationCode] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { userRole } = useContext(AuthContext); // Destructure userRole from AuthContext

    useEffect(() => {
        // Redirect based on user role
        if (userRole === 'fulluser' || userRole === 'poweruser') {
            navigate('/dashboard');
        } else if (userRole === 'partialuser') {
            navigate('/mini-dashboard');
        }
    }, [userRole, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!invitationCode.trim()) {
            setMessage('Please enter a valid invitation code.');
            return;
        }
        try {
            const response = await axios.get(`https://extreme-wind-446702-f9.et.r.appspot.com/validate_invitation_code?code=${invitationCode}`);
            if (response.data.valid) {
                navigate(`/user-registration?code=${invitationCode}`);
            } else {
                setMessage('Invitation code is invalid.');
            }
        } catch (error) {
            setMessage('Error checking invitation code.');
        }
    };

    return (
        <div className="enter-invitation-container">
            <h1 className="enter-invitation-header">Enter Your First Invitation Code</h1>
            <p className="enter-invitation-paragraph">
                If you are verifying your second invite,{' '}  
                <Link to="/login" className="login-link">log in</Link>
                {' '}to your account to enter the invitation code.
            </p>
            <form onSubmit={handleSubmit} className="enter-invitation-form">
                <input 
                    type="text" 
                    className="invitation-code-input"
                    value={invitationCode}
                    onChange={(e) => setInvitationCode(e.target.value)}
                    placeholder="Enter your code here"
                />
                <button type="submit" className="submit-invitation-button">Submit</button>
            </form>
            {message && <p className="invitation-message">{message}</p>}
        </div>
    );
};

export default EnterInvitationPage;
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

const InvitationStats = () => {
    const { currentUser, idToken } = useContext(AuthContext);
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            if (currentUser && idToken) {
                setLoading(true);
                try {
                    const response = await axios.get(`https://extreme-wind-446702-f9.et.r.appspot.com/invitation_stats/${currentUser.uid}`, {
                        headers: { Authorization: `Bearer ${idToken}` }
                    });
                    setStats(response.data);
                } catch (error) {
                    console.error('Failed to fetch invitation stats:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchStats();
    }, [currentUser, idToken]);

    return (
        <div className="invitation-stats">
            <h3>Invitation Code Overview</h3>
            {loading ? <p>Loading...</p> : (
                <>
                    <div className="stat-item total">Created: {stats.total}</div>
                    <div className="stats-container">
                        <div className="stat-item used">Used: {stats.used}</div>
                        <div className="stat-item active">Active: {stats.active}</div>
                        <div className="stat-item revoked">Revoked: {stats.revoked}</div>
                        <div className="stat-item expired">Expired: {stats.expired}</div>
                    </div>
                </>
            )}
        </div>
    );
};

export default InvitationStats;
